<template>
  <div id="pathwaybrowsecompnent">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <el-container>
      <el-main>
        <el-table :data="pathwayData" style="width: 100%" @row-click="detailSearch">
          <el-table-column label="Pathway ID" width="180">
            <template #default="scope">
              <p>{{ scope.row.pathway }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="annotation" label="Annotation"></el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <div style="display: flex; justify-content: center">
      <el-pagination background layout="prev, pager, next" :total="153" :page-size="querydata.size"
        :current-page="querydata.page" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import request from '@/network/request'
  import vis from "vis-network/dist/vis-network.min.js"
  import "vis-network/dist/dist/vis-network.min.css"
  // const vis = require("vis-network/dist/vis-network.min.js")
  // require("vis-network/dist/dist/vis-network.min.css")
  import $ from 'jquery'
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  let canvas
  let context1
  export default {
    name: 'MetaboliteBrowseCompnent',
    data () {
      return {
        querydata: {
          page: 1,
          size: 20,
        },
        paginationArg: {

        },
        pathwayData: "",
        head: "Requesting !",
        count: 0
      }
    },
    methods: {
      test () {
        this.count += 1
      },
      requestData () {
        request({
          url: 'KeggPathway/list/',
          params: this.querydata,
        }).then(res => {
          this.pathwayData = res
          this.head = "Pathway List"
        }).catch(err => {
          console.log(err);
        });
      },
      handleSizeChange (size) {
        this.pagesize = size
      },
      handleCurrentChange (currentPage) {
        this.querydata.page = currentPage
      },
      detailSearch (row, column, event) {
        this.$router.push({ name: 'NetworkDetail', params: { format: 'json', searchItem: row.pathway } })
        // this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: metabolite } })
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch
    },
    created () {
      // this.requestData()
    },
    mounted () {
    },
    updated () {

    },
    // 检测querydata的编号
    watch: {
      querydata: {
        handler (newName, oldName) {
          this.requestData()
        },
        immediate: true,
        deep: true,
      },
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
    font-size: 20px;
  }
</style>